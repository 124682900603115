import { Component, OnInit, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import * as _ from "lodash";

import { Constants } from "./../../../constants/constants";
import { SharedService } from "./../../../services/shared.service";
import { ClustersService } from "../../../pages/clusters/clusters.service";
import { BroadcastersService } from "./../broadcasters.service";
import { Broadcaster, AccessKey } from "./../../../models/shared";
import { Cluster } from "../../../pages/clusters/cluster";
import { ModalService } from "../../shared/modals/modal.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TitleService } from "../../../services/title.service";
import { urlBuilder } from "@zixi/shared-utils";
import { firstValueFrom } from "rxjs";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { LocationInfo } from "src/app/pages/maps/map";

@Component({
    selector: "app-broadcaster-form",
    templateUrl: "./broadcaster-form.component.html"
})
export class BroadcasterFormComponent implements OnInit {
    bxTypes = {
        Backup: 1,
        Primary: 0
    };
    cluster: Cluster;
    broadcaster: Broadcaster;
    private clusterId: number;
    private existingBroadcaster: Broadcaster;
    private broadcasters: Broadcaster[];
    private broadcasterId: number;
    private broadcasterName: string;
    private action: string;
    broadcasterNames: string[];
    accessKeys: AccessKey[];
    hasRemainingIps: boolean;
    manualBroadcaster = false;

    submitted = false;
    isEdit = false;
    constants = Constants;

    loading = true;
    saving = false;

    private route = inject(ActivatedRoute);
    private router = inject(Router);
    private cs = inject(ClustersService);
    private bs = inject(BroadcastersService);
    private modalService = inject(ModalService);
    private sharedService = inject(SharedService);
    private mixpanelService = inject(MixpanelService);
    private titleService = inject(TitleService);

    isAutoDetectControl = new FormControl(true);
    noPrivateIpControl = new FormControl(true);
    form = inject(FormBuilder).group({
        name: [
            null as string,
            [Validators.required, Validators.minLength(3), Validators.pattern(this.constants.validators.name)]
        ],
        location: [undefined as LocationInfo],
        api_user: ["admin", [Validators.required]],
        api_password: ["", [Validators.required]],
        remote_access_key_id: [null as number, [Validators.required]],
        is_backup: [0],
        streaming_ip: [
            { value: "", disabled: true },
            [Validators.pattern(Constants.validators.bx_ip), Validators.required]
        ],
        private_ip: [
            { value: "", disabled: true },
            [Validators.pattern(Constants.validators.bx_ip), Validators.required]
        ],
        input_bw_limit: [0, [Validators.min(0)]],
        output_bw_limit: [0, [Validators.min(0)]]
    });
    cancelUrl = [];

    private prepForm() {
        this.titleService.setTitle("BROADCASTER", this.action, this.broadcaster);

        if (this.action && this.broadcaster) {
            if (this.broadcaster.streaming_ip) this.isAutoDetectControl.setValue(false);
            if (this.broadcaster.private_ip) this.noPrivateIpControl.setValue(false);
            this.form.patchValue({
                ...this.broadcaster,
                location: this.broadcaster.location.address,
                remote_access_key_id: this.broadcaster.accessKey.id
            });
        }

        this.manualBroadcaster =
            !this.cluster.aws_account_id &&
            !this.cluster.azure_account_id &&
            !this.cluster.gcp_account_id &&
            !this.cluster.linode_account_id;

        if (this.cluster._frontData?.is_auto_scaling && !this.isEdit) {
            this.form.controls.name.setValue(this.generateBroadcasterName());
        }

        this.hasRemainingIps = this.clusterHasRemainingIps();

        if (!this.cluster._frontData.is_auto_scaling || this.isEdit) {
            this.form.controls.name.addValidators([Validators.required]);
        }
        if (this.cluster._frontData.is_auto_scaling) {
            this.form.controls.api_user.disable();
            this.form.controls.api_password.disable();
            this.form.controls.remote_access_key_id.disable();
        }
        if (this.cluster._frontData.is_auto_scaling && this.isEdit) {
            this.form.controls.is_backup.disable();
        }
    }

    private clusterHasRemainingIps() {
        if (this.cluster?.broadcasters && (this.cluster.eips || this.cluster.require_eip)) {
            const broadcastersOnCluster = this.cluster.broadcasters;
            const numberOfTotalIps = this.cluster.eips?.split(",").length;
            let numberOfUsedIps = 0;
            broadcastersOnCluster.forEach(broadcaster => {
                if (broadcaster.eip_allocation_id !== null) {
                    numberOfUsedIps++;
                }
            });
            return numberOfTotalIps > numberOfUsedIps;
        } else {
            return true;
        }
    }

    private async getBroadcasters(id: number) {
        const result = await firstValueFrom(this.bs.refreshBroadcasters(id, true));

        if (result) {
            this.broadcasters = result;
            this.broadcasterNames = _.map(this.broadcasters, "name");
            // If Edit remove the current broadcasterName from broadcasterNames list
            if (this.isEdit) {
                this.broadcasterNames = _.without(this.broadcasterNames, this.broadcasterName);
            }
            // Set Broadcaster
            if (this.broadcasterId) {
                this.broadcaster = this.broadcasters.find(b => b.id === this.broadcasterId);
                this.existingBroadcaster = _.cloneDeep(this.broadcaster);
            }
        }
    }

    async ngOnInit() {
        const params = this.route.snapshot.params;
        this.clusterId = urlBuilder.decode(params.clusterId);
        this.action = params.action;
        this.broadcasterId = urlBuilder.decode(params.broadcasterId);
        this.broadcasterName = params.broadcasterName;

        if (this.action === "edit") this.isEdit = true;
        this.accessKeys = await firstValueFrom(this.sharedService.getAccessKeys());

        this.cluster = _.cloneDeep(this.cs.getCachedCluster(null, this.clusterId));
        // Check if found in cache
        if (this.sharedService.isEmptyObject(this.cluster)) {
            await firstValueFrom(this.cs.refreshClusters(true));
            this.cluster = _.cloneDeep(this.cs.getCachedCluster(null, this.clusterId));
        }
        await this.getBroadcasters(this.cluster.id);

        this.isAutoDetectControl.valueChanges.subscribe(value =>
            value ? this.form.controls.streaming_ip.disable() : this.form.controls.streaming_ip.enable()
        );
        this.noPrivateIpControl.valueChanges.subscribe(value =>
            value ? this.form.controls.private_ip.disable() : this.form.controls.private_ip.enable()
        );

        this.prepForm();
        this.loading = false;
        this.cancelUrl = urlBuilder.getRegularClusterUrl(this.cluster.id, this.cluster.name);
    }

    async onSubmit() {
        this.saving = true;

        const model = {
            ...this.form.value,
            streaming_ip: this.isAutoDetectControl.value ? null : this.form.controls.streaming_ip.value,
            private_ip: this.noPrivateIpControl.value ? null : this.form.controls.private_ip.value,
            broadcaster_cluster_id:
                !this.isEdit && !this.cluster._frontData.is_auto_scaling ? this.cluster.id : undefined
        };

        if (this.isEdit) {
            const changedData = this.sharedService.getZixiObjDiff(model, this.existingBroadcaster, []);
            const isEmptyData = this.sharedService.isEmptyObject(changedData);

            if (!isEmptyData) {
                const updatedBroadcaster = await this.bs.updateBroadcaster(this.broadcaster, {
                    ...changedData,
                    restart_confirmed: false
                });
                // Restart Notice
                if (updatedBroadcaster === true) {
                    await this.modalService.confirm(
                        "SAVE_RESTART",
                        "BROADCASTER",
                        async () => {
                            const updateAndRestartBroadcaster = await this.bs.updateBroadcaster(this.broadcaster, {
                                ...changedData,
                                restart_confirmed: true
                            });
                            if (updateAndRestartBroadcaster && updateAndRestartBroadcaster !== true) {
                                this.saving = false;
                                this.mixpanelService.sendEvent("update & restart broadcaster", {
                                    updated: Object.keys(changedData)
                                });
                                this.router.navigate(
                                    urlBuilder.getRegularBroadcasterUrl(
                                        this.cluster.id,
                                        updateAndRestartBroadcaster.id,
                                        updateAndRestartBroadcaster.name
                                    )
                                );
                            } else {
                                this.saving = false;
                            }
                        },
                        model.name
                    );
                    this.saving = false;
                } else if (updatedBroadcaster) {
                    this.saving = false;
                    this.mixpanelService.sendEvent("update broadcaster", {
                        updated: Object.keys(changedData)
                    });
                    this.router.navigate(
                        urlBuilder.getRegularBroadcasterUrl(
                            this.cluster.id,
                            this.broadcaster.id,
                            updatedBroadcaster.name
                        )
                    );
                } else this.saving = false;
            } else {
                this.saving = false;
                this.isEdit
                    ? this.router.navigate(
                          urlBuilder.getRegularBroadcasterUrl(
                              this.cluster.id,
                              this.broadcaster.id,
                              this.broadcaster.name
                          )
                      )
                    : this.router.navigate(urlBuilder.getRegularClusterUrl(this.cluster.id, this.cluster.name));
            }
        } else {
            const result = await this.bs.addBroadcaster(this.cluster.id, model);
            if (result) {
                this.mixpanelService.sendEvent("create broadcaster");
                this.router.navigate(urlBuilder.getRegularBroadcasterUrl(this.cluster.id, result.id, result.name));
            }
        }

        this.saving = false;
    }

    private generateBroadcasterName() {
        let counter = this.cluster.broadcasters.length + 1;
        do {
            const bxName = `${this.cluster.name}-${counter}`;
            if (!this.cluster.broadcasters.find(b => b.name === bxName)) return bxName;
            counter++;
        } while (true);
    }
}
